exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-announcement-js": () => import("./../../../src/pages/announcement.js" /* webpackChunkName: "component---src-pages-announcement-js" */),
  "component---src-pages-bar-js": () => import("./../../../src/pages/bar.js" /* webpackChunkName: "component---src-pages-bar-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-endo-kazutoshi-js": () => import("./../../../src/pages/endo-kazutoshi.js" /* webpackChunkName: "component---src-pages-endo-kazutoshi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-omakase-js": () => import("./../../../src/pages/omakase.js" /* webpackChunkName: "component---src-pages-omakase-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-waitinglist-js": () => import("./../../../src/pages/waitinglist.js" /* webpackChunkName: "component---src-pages-waitinglist-js" */)
}

